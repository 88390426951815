/**
 * @file Parent class for our list of filters--defines the sidebar and leaves implementation of individual sets to child classes
 * @author Jimmy Messmer
 */
import React from 'react'
import {
  Row,
  Col,
  Button,
  Tooltip
} from 'reactstrap'

import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import FilterSet from './FilterSet'

// import AgeFilterSet from './AgeFilterSet'

import { Error } from '../utils'
import { Link } from 'react-router-dom'

/**
 * React component for the FilterList.
 * @class FilterList
 */
class FilterList extends React.Component {
  /**
   * Create a new FilterList
   * @param {*} props React props
   */
  constructor(props) {
    super(props)
    this.setFilters = this.setFilters.bind(this)
    this.clear = this.clear.bind(this)
    this.toggle = this.toggle.bind(this)
    this.generateURLParams = this.generateURLParams.bind(this)
    this.state = {
      loaded: false,
      filters: this.props.filters,
      prevFilters: this.props.filters,
      clear: true,
      ttOpen: false,
      active: true
    }
  }

  componentDidMount() {
    this.setState({
      loaded: true,
      tags: this.props.filters,
      active: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.filters) !== JSON.stringify(this.props.filters)) {
      this.setState({
        filters: this.props.filters,
        active: true
      })
    }
  }


/**
 * Generate URL parameters based on selection of filters. 
 * This function is called whenever the filters are changed.  
 *
 * @param {Array<string>} diff - An array of differences to apply to the URL parameters.
 * @return {void} This function does not return a value.
 */
generateURLParams(diff) {
  const urlParams = new URLSearchParams(window.location.search);
  
  diff.forEach(d => {
    const [key, value] = d.split("|");
    const encodedValue = btoa(value);
    

    if (urlParams.has(key)) {
      // If the key exists, update the value
      const existingValues = urlParams.getAll(key)[0].split(',');

      if (existingValues.length > 0) {
        if (!existingValues.includes(encodedValue)) {
          // Combine existing and new values into a single parameter if they share same key
          urlParams.set(key, [...existingValues, encodedValue].join(','));
        } else {
          // Remove the value if it already exists
          urlParams.set(key, existingValues.filter(v => v !== encodedValue).join(','));

          // If no values are left, remove the key
          if (urlParams.get(key) === '') {
            urlParams.delete(key);
          }
        }
      } else {
        // Remove the key if it has no values
        urlParams.delete(key);
      }
    } else {
      // Append the new key-value pair if new key
      urlParams.append(key, encodedValue);
    }
  });

  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${urlParams.toString()}`;
  window.history.pushState({ 'pageTitle': 'Your Page Title' }, '', newUrl);
}

 

  /**
   * Generate an updated list of active tags and store that in the URL. Then call the callback method in ImageGrid to propogate changes.
   * @param {array} newTags Array of new tags to add
   */
  setFilters(newTags, diff) {
    this.generateURLParams(diff)
    this.props.callback(newTags, diff)
  }

  /**
   * Clear all active filters.
   */
  clear() {
    this.props.clear({})
    this.setState({
      active: false
    })
  }

  toggle() {
    this.setState({
      ttOpen: !this.state.ttOpen
    })
  }

  render() {
    if (this.props.tags !== null) {
      return (
        <div className='filter-list'>
          <Row className='pancreatlas-row mr-1'>
            <Col className='text-left' md='8'>
              <h3>
                <strong>Filters:</strong>
              </h3>
            </Col>
            <Col className='text-right  filters-header' md='4'>
            <div id='QuestionCircle'>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </div>
              <Tooltip placement='right'
                isOpen={this.state.ttOpen}
                target='QuestionCircle'
                toggle={this.toggle}>
                The filters work as an AND function between groups and an OR within them. Example: (Childhood) AND (F OR M)
              </Tooltip>
              <Button outline className='filter-button text-center' color='danger' size='sm' onClick={this.clear}><i
                className='fa fa-home fa-fw' />Clear</Button>
            </Col>
          </Row>
          <Row className='pancreatlas-row'>
            <div className='text-left'> 
            <p className='filters-text'>For descriptions of metadata fields and abbreviations, click <Link to={'/nomenclature'} target="_blank" rel="noopener noreferrer">here</Link>.</p>
            </div>
          
          </Row>
          {this.props.filters.children.map((filterSet,idx) => {
            if (filterSet.name === 'DATASET' && filterSet.children.length === 1)
              return null;
            return (
              <div className='filter-set' key={idx}>
                <FilterSet onClear={this.state.active} setName={filterSet.name} node={filterSet} callback={this.setFilters} depth={1} />
              </div>
            )
          })}
        </div>
      )
    } else if (this.state.error !== undefined) {
      return <Error error_desc={this.state.error.message} />
    } else {
      return null
    }
  }
}

FilterList.defaultProps = {
  filters: ['filter 1', 'filter 2', 'filter 3', 'filter 4'],
  tags: {}
}

export default FilterList
