import React from 'react'


export default class DatasetFetchError extends React.Component {
  render () {
    return (
      <div className='error'>
        <h1>Error Fetching Datasets</h1>
        <p>Please try again later</p>
      </div>
    )
  }
}

Error.defaultProps = {
  error_title: 'Unknown Error',
  error_desc: 'No more info'
}
