import React from 'react'
import { Container, Row, Col, Alert, Badge, Button, ButtonGroup, Input, Form, FormGroup, Label } from 'reactstrap'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { Error, LoadingBar, Pagination } from '../utils'
import { FilterTree, compareAges } from '../../tools/utilities'
import { FilterList } from '../filtering'
import ImageCard from './ImageCard'
import { fetchDataset } from '../../tools/api' 
import OmicsCard from './OmicsCard'




export default class ImageGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      tags: null,
      ids: [],
      matches: [],
      filters: {},
      prevFilters: {},
      datasetName: '',
      imgsPerRow: 3,
      imgsColSplit: 4,
      rowsPerPage: 5,
      density: 'normal',
      filterTree: new FilterTree()
    }

    this.filter = this.filter.bind(this)
    this.callback = this.callback.bind(this)
    this.undo = this.undo.bind(this)
    this.clear = this.clear.bind(this)
    this.markerFilter = this.markerFilter.bind(this)
    this.setDensity = this.setDensity.bind(this)
    this.sortImgs = this.sortImgs.bind(this)
    this.image_tags = {}
  }
    
async componentDidMount() {
  window.scrollTo(0, 0);
  



  const imageUrl = this.props.did === "1138" ?
    `${process.env.REACT_APP_API_URL}/datasets/1138/get-omics`: this.props.did === undefined ?
    `${process.env.REACT_APP_API_URL}/datasets/list-all`:
    `${process.env.REACT_APP_API_URL}/datasets/${this.props.did}/get-images`

  try {
    if(this.props.did !== "1138" && this.props.did !== undefined) {
      const datasetData = await fetchDataset(this.props.did);
    this.setState({
      datasetName: datasetData.dsname,
    });
    }else if(this.props.did === "1138"){
      this.setState({
        datasetName: 'Single Cell OMICs Datasets'
      })
    }else{
      this.setState({
        datasetName: 'All Pancreatlas Images'
      })
    }

    let imageDataResponse = {}
    if(this.props.did !== "1138"){
    imageDataResponse = await axios.get(imageUrl, {
      withCredentials: true,
      credentials: 'include',
      headers: {
        Authorization: process.env.REACT_APP_API_AUTH,
      },
    });
  }else{
    imageDataResponse = await axios.get(`${process.env.REACT_APP_API_URL}/datasets/1138/get-omics`, {
      
    })
  }

    const { images, filters } = imageDataResponse.data;

    this.processTags(filters);
    this.processImages(images);

    const activeImages = this.state.filterTree.generateActiveImages();
    this.setState({
      loaded: true,
      ids: imageDataResponse.data,
      dataTypes: imageDataResponse.data.data_type,
      maxPages: Math.floor(Object.keys(imageDataResponse.data).length / (this.state.imgsPerRow * this.state.rowsPerPage)),
      matches: activeImages,
      page: 1,
    });

    if (this.props.iid && this.props.iid > 0) {
      this.setModal(this.props.iid);
    }
    
    this.checkURLParams();

    if (this.props.userInput) {
      this.filter({ MARKER: [this.props.userInput] }, [`MARKER|${this.props.userInput}`]);
    }
  } catch (error) {
    console.error(error);
    this.setState({
      loaded: false,
      error: error,
    });
  }
}


checkURLParams(){
  if(window.location.search != ''){
    const urlParams = new URLSearchParams(window.location.search)

    for (let [key, value] of urlParams.entries()) {
      let entries = value.split(",")
      for (let entry of entries) {
        let decodedValue = atob(entry)
      //logic for PANC-DB external links, if links are changed, we can remove this
      if(decodedValue.includes("[")){
        const regex = /\["([^"]+)"\]/;
        const match = decodedValue.match(regex);
        if(match){
          const existingValues = urlParams.getAll(key)[0].split(',');
          decodedValue = match[1]
          let encodedValue = btoa(decodedValue)
          existingValues.push(encodedValue)
          urlParams.set(key, existingValues.filter(v => v !== value).join(','))
          const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${urlParams.toString()}`;
          window.history.pushState({ 'pageTitle': 'Your Page Title' }, '', newUrl);
        }
      }
      this.state.filterTree.activateFilter(`${key}|${decodedValue}`)
    }
      
    }
  }
  const imageMatches = this.state.filterTree.generateActiveImages()
        this.setState({
          matches: imageMatches,
          page: 1
        })
}

  processTags(tags) {
    for (let tagset of tags) {
      let tagsetName = tagset.set_name;
      for (let tag of Object.keys(tagset.tags)) {
        this.addTagToFilterTree(tag, tagsetName);
      }
    }
  }

  addTagToFilterTree(tag, tagsetName) {
    let ageRe = /AGE|DISEASE DURATION*/i;
    let sliderRe = /(AMYLOID PREVALENCE|TRICHROME|ALPHA CELLS|BETA CELLS|DELTA CELLS|ENDOCRINE TISSUE|EXOCRINE TISSUE) \(%\)/i;
    let standardSortRe = /ACINAR ATROPHY|INTRALOBULAR ADIPOSITY|INTERLOBULAR ADIPOSITY|ANGIOPATHY|ADM|PANIN*/i;
    let defaultHiddenRe = /LIMS ID|PROGRAM ID*|RRID/i;
    let filterMethod = ageRe.test(tagsetName) || sliderRe.test(tagsetName) ? 'slider' : 'checkbox';
    let hidden = defaultHiddenRe.test(tagsetName);

    let sortMethod;
    if (ageRe.test(tagsetName)) {
      sortMethod = (a, b) => compareAges(a.name, b.name);
    } else if (sliderRe.test(tagsetName) || standardSortRe.test(tagsetName)) {
      sortMethod = (a, b) => parseFloat(a.name) - parseFloat(b.name);
    } else {
      sortMethod = (a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
    }

    this.state.filterTree.addNode(tag, tagsetName, sortMethod, filterMethod, hidden);
  }

  processImages(images) {
    for (let img of Object.keys(images)) {
      for (let tagset of images[img]) {
        this.state.filterTree.addImg(`${tagset.tagset.toUpperCase()}|${tagset.tag}`, img);
      }
    }
  }


  undo() {
    this.state.filterTree.undo()


    this.setState({
      matches: this.state.filterTree.generateActiveImages()
    })
  }

  clear() {
    this.state.filterTree.resetTo(false)
    window.history.pushState({ 'pageTitle': 'Browse & Filter Dataset' }, '', `${window.location.protocol}//${window.location.host}${window.location.pathname}`)
    this.setState({
      matches: this.state.filterTree.generateActiveImages(),
      sortOrder: "sel"


    })
  }


  //activates filter
  filter(newTags, diff) {
    for (const d of diff) {
      if (!this.state.filterTree.allowMultipleSelection((d.split('|')[0]))) {
        this.state.filterTree.clearDisabledState()
      }
      this.state.filterTree.activateFilter(d);

    const imageMatches = this.state.filterTree.generateActiveImages();

    if (
      this.state.filterTree.operationStack.length > 0 &&
      this.state.filterTree.root.children[0].category === 'DATASET' &&
      this.state.filterTree.root.children[0].children.length > 1
    ) {
      this.state.filterTree.toggleDisableFlag(imageMatches);
    }
    this.setState({
      matches: imageMatches,
      page: 1
    });
  }
}

  markerFilter(marker) {
    this.filter({MARKER: [marker]},[`MARKER|${marker}`])

  }

  callback(iid, tags) {
    this.image_tags[iid] = tags
  }

  setDensity(density) {
    switch (density.toLowerCase()) {
      case 'sparse':
        this.setState({
          imgsPerRow: 2,
          imgsColSplit: 6,
          rowsPerPage: 6,
          maxPages: Math.floor((Object.keys(this.state.ids).length / (2 * 6))),
          density: 'sparse'
        })
        break
      case 'dense':
        this.setState({
          imgsPerRow: 4,
          imgsColSplit: 3,
          rowsPerPage: 4,
          maxPages: Math.floor((Object.keys(this.state.ids).length / (4 * 4))),
          density: 'dense'
        })
        break
      default:
        this.setState({
          imgsPerRow: 3,
          imgsColSplit: 4,
          rowsPerPage: 5,
          maxPages: Math.floor((Object.keys(this.state.ids).length / (3 * 5))),
          density: 'normal'
        })
        break
    }
  }

  sortImgs(event) {
    let sorted = []
    switch (event.target.value) {
      case 'sel':
        sorted = this.state.filterTree.generateActiveImages()
        break
      case 'duration-asc':
        sorted = this.state.filterTree.sortImages('DISEASE DURATION')
        break
      case 'duration-desc':
        sorted = this.state.filterTree.sortImages('DISEASE DURATION')
        break
      case 'age-desc':
        sorted = this.state.filterTree.sortImages('AGE', ((a, b) => -1 * compareAges(a.value, b.value)))
        break
      case 'age-asc':
      default:
        sorted = this.state.filterTree.sortImages('AGE', ((a, b) => compareAges(a.value, b.value)))
        break
    }

    let activeSorted = sorted.filter(img => this.state.matches.includes(img))

    this.setState({
      sortOrder: event.target.value,
      matches: activeSorted
    })
  }

  render() {
    if (this.state.loaded) {
      if (this.state.matches.length === 0) {
        return (
          <div className='no-results'>
            <MetaTags>
              <title>Browse &amp; Filter Dataset -- Pancreatlas / {this.state.datasetName}</title>
                <meta name='description' content={`View an entire dataset in the pancreatlas ${this.state.datasetName}`} />
            </MetaTags>
            <Container>
              <Alert color='info'>
                <Row>
                  <Col m='6'>
                    You are currently viewing <Badge color='info'>{this.state.matches.length}</Badge> out of a possible <Badge color='secondary'>{Object.keys(this.state.ids.images).length}</Badge> images
                  </Col>
                  <Col m='6'>
                    <span className='float-right'>Dataset: <strong>{this.state.datasetName}</strong></span>
                  </Col>
                </Row>
              </Alert>
            </Container>
            <Container style={{display:'flex',justifyContent:'center'}}>
              <Row className='pancreatlas-row' style={{width: '100%',display: 'flex', justifyContent: 'center'}}>
                <Col md='9'>
                  <Alert color='danger'>
                    <Row>
                      <Col md='12'>
                        <p>The combination of filters you have used returns 0 images.</p>
                        <span/>
                        <Button color='danger' className='undo' onClick={this.clear}>Undo your most recent change?</Button>
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
            </Container>
          </div>
        )
      }

      var imagesPerPage = this.state.imgsPerRow * this.state.rowsPerPage





      let imgGrid = []
      let start = imagesPerPage * (this.state.page -1)
      let end = start + imagesPerPage
      let slice = this.state.matches.slice(start, end)
      while (slice.length) {
        imgGrid.push(slice.splice(0, this.state.imgsPerRow))
      }


      return (
        <div className='image-grid'>
          <MetaTags>
            <title>Browse &amp; Filter {this.state.datasetName} data -- Pancreatlas</title>
            <meta name='description' content={`View an entire dataset in the pancreatlas ${this.state.datasetName}`} />
          </MetaTags>
          <Container>
            <Alert color='info'>
              <Row>
                <Col m='6'>
                  You are currently viewing <Badge color='info'>{this.state.matches.length}</Badge> out of a possible <Badge color='secondary'>{Object.keys(this.state.ids.images).length}</Badge> images
                </Col>
                <Col m='6'>
                  <span className='float-right'>Dataset: <strong>{this.state.datasetName}</strong></span>
                </Col>
              </Row>
              <Row>
                <Col md='6'>
                  {this.props.did && <div className='float-left'>View more about this dataset <Link to={`/datasets/${this.props.did}/overview`}><strong><u>here</u></strong>.</Link> </div>}
                </Col>
                <Col md='6'>
                  <Row>
                    <Col xs='12'>
                      <div className='grid-options float-right'>
                        <Form inline>
                          <FormGroup>
                            <Label for='sort-select' className='pr-1'>Sort by: </Label>
                            <Input bsSize='sm' type='select' name='sort-select' id='sort-select' value={this.state.sortOrder} onChange={this.sortImgs}>
                              <option value='sel'>Image ID</option>
                              <option value='age-asc'>Age Ascending</option>
                              <option value='age-desc'>Age Descending</option>
                            </Input>
                          </FormGroup>
                          <span className='pl-2'>
                            <span className='pr-1'>Grid Density: </span>
                            <ButtonGroup size='sm' className='pl-1'>
                              <Button color='info' onClick={() => this.setDensity('sparse')} active={this.state.density === 'sparse'}>Sparse</Button>
                              <Button color='info' onClick={() => this.setDensity('normal')} active={this.state.density === 'normal'}>Normal</Button>
                              <Button color='info' onClick={() => this.setDensity('dense')} active={this.state.density === 'dense'}>Dense</Button>
                            </ButtonGroup>
                          </span>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Alert>
          </Container>

          <Container>
            <Row className='pancreatlas-row'>
              <Col md='3'>
                <FilterList ageGroup={this.props.groupName} filters={this.state.filterTree.generateJSON(this.state.filterTree.root)} callback={this.filter} clear={this.clear} />
              </Col>
              <Col md='9'>
                {imgGrid.map((item, idx) => (
                  <Row key={idx} className='image-row pancreatlas-row'>
                    {item.map((image, idx) =>
                      <Col key={idx} md={this.state.imgsColSplit}>
                        {
                          this.state.dataTypes[image] === 'omero' ? <ImageCard filterActive isFavorite={this.props.favorites.indexOf(image) === -1} favoriteCallback={this.props.favoriteCallback} key={image} did={this.props.did} iid={image} callback={this.setModal} dataType={this.state.dataTypes[image]} filterCallback={this.markerFilter} />:
                          <OmicsCard filterActive isFavorite={this.props.favorites.indexOf(image) === -1} favoriteCallback={this.props.favoriteCallback} key={image} did={this.props.did} iid={image} callback={this.setModal} dataType={this.state.dataTypes[image]} filterCallback={this.markerFilter} />
                        }
                       
                      </Col>
                    )}
                  </Row>
                ))}
                <Row className='pancreatlas-row'>
                  <Col md='12'>
                    <Pagination className="pagination-bar" currentPage = {this.state.page} totalCount = {this.state.matches.length} pageSize = {imagesPerPage} onPageChange = { page => this.setState({page: page})}/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      )
    } else if (this.state.error !== undefined) {
      return <Error error_desc={this.state.error.message} />
    } else {
      return (
        <LoadingBar loadingInfo={`dataset ${this.state.datasetName} ...`} />
      )
    }
  }
}
