import React from 'react'

import {
  Table,
  Button,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'

import {
  Link
} from 'react-router-dom'

import Error from './Errors/Error'
import LoadingBar from './LoadingBar'
import DatasetCard from './DatasetCard'

import axios from 'axios'
import ExploreAllDatasetCard from './ExploreAllDatasetCard'
import OmicsDatasetCard from './OmicsDatasetCard'

export default class DatasetList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      datasets: [],
      activeTab: '0',
      totalImages: 0
    }
    let params = new URLSearchParams(window.location.search)
    this.iids = (params.has('iids') ? params.get('iids') : window.btoa(JSON.stringify([])))
  }

  componentDidMount() {
    const hash = window.location.hash;
    if(hash === "#listview"){
      this.setState({activeTab: "1"})
    }
    axios.get(`${process.env.REACT_APP_API_URL}/datasets/`, {
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Authorization': process.env.REACT_APP_API_AUTH
      }
    }).then(result => {
      let totalImages = 0
      result.data.map(item => {
        totalImages += parseInt(item.kvals.img_count)})
        totalImages += 2
      this.setState({
        loaded: true,
        datasets: result.data.sort(function (a, b) {
          if (a.did < b.did) return -1
          if (a.did > b.did) return 1
          return 0
        }),
        totalImages: totalImages.toLocaleString()
      })
    }).catch(err => {
      this.setState({
        error: err
      })
    })

    
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  render() {
    if (this.state.loaded) {
      return (
        <div className='dataset-lists'>
          {/* <h1 className='mb-4'>Image Collections</h1> */}
          <Nav tabs>
            <NavItem>
              <NavLink className={`dataset-nav ${(this.state.activeTab === '0') ? 'active' : undefined} navlink`} onClick={() => { this.toggle('0') }}>
                Grid View
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`dataset-nav ${(this.state.activeTab === '1') ? 'active' : undefined} navlink`} onClick={() => { this.toggle('1') }}>
                List View
              </NavLink>
            </NavItem>

          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId='0'>
              <div className='dataset-cards'>
                <Row>
                  <Col className='mb-4' md='4'>
                    <ExploreAllDatasetCard title='Explore all data' description={`Access ${this.state.totalImages} images across ${this.state.datasets.length} collections`} did="all"/>
                  </Col>
                  {this.state.datasets.map(item => {
                    return (
                    <Col className='mb-4' key={`${item.dsname}-col`} md='4'>
                      <DatasetCard key={item.dsname} title={item.dsname} description={item.desc || undefined} funding={item.kvals.funding} did={item.did} />
                    </Col>
                  )})}
                  <Col className='mb-4' key={`omics-col`} md='4'>
                      <OmicsDatasetCard key={'omics'} title={'Single Cell OMICs Datasets'} description={'Human islet single cell RNA sequencing data highlights islet cell heterogeneity.'} funding={"none"} did={1138} />
                    </Col>
                </Row>
              </div>
            </TabPane>
            <TabPane tabId='1'>
              <Row>
                <Col md='12'>
                  <div className='table table-responsive'>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th className='text-center'># of Images or Data</th>
                          <th className='text-center'></th>
                          <th className='text-center'>Image or Data Types</th>
                          <th className='text-center'>ID</th>
                          <th className='text-center'>Publish Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.datasets.map(item => (
                          <tr key={item.did}>
                            {/* <Badge color='dark' pill><a href='dataset'>? Learn more</a></Badge> */}
                            <td><b>{item.dsname}</b><br />
                              {item.desc || ''}
                            </td>
                            <td className='text-center'>{item.kvals.img_count}</td>
                            <td className='text-center'>
                              <Row>
                                <Link to={{ pathname: `/datasets/${item.did}/explore` }}>
                                  <Button className='ds-list-left-button'>Browse All Images</Button>
                                </Link>
                              </Row>
                            </td>
                            <td className='text-center'>{item.kvals.img_types}</td>
                            <td className='text-center'>{item.did}</td>
                            <td className='text-center'>{item.kvals.release_date}</td>
                          </tr>
                        ))}
                        <tr key='1138'>
                            <td><b>Single Cell OMICs Datasets</b><br />
                            Human single cell RNA-Seq datasets representing disease, stress response and heterogeneity.
                            </td>
                            <td className='text-center'>2</td>
                            <td className='text-center'>
                              <Row>
                                <Link to={{ pathname: `/datasets/1138/explore` }}>
                                  <Button className='ds-list-left-button'>Browse All Images</Button>
                                </Link>
                              </Row>
                            </td>
                            <td className='text-center'>scRNA-seq</td>
                            <td className='text-center'>1138</td>
                            <td className='text-center'>2025-04-10</td>
                          </tr>
                        <tr><td className="text-right">Total count</td><td className="text-center">{this.state.totalImages}</td><td colSpan="6">&nbsp;</td></tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          {/* <Link to='/explore-all-images'>
            <Button color='primary'>Explore all Images</Button>
          </Link> */}
        </div>
      )
    } else if (this.state.error !== undefined) {
      return <Container><Error error_desk={this.state.error.message} /></Container>
    } else {
      return <LoadingBar loadingInfo='list of datasets' />
    }
  }
}
