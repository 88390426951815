import React from 'react'
import MetaTags from 'react-meta-tags'



export default class Data extends React.Component {
render(){
return(
    <div className='data-container'>
        <MetaTags>
          <title>Data -- Pancreatlas</title>
          <meta name='description' content='Data page description' />
        </MetaTags>
    <div className='mb-4'>
            <div className='data-top-text'>
            <h2>Data Page</h2>
              <div>
                <p>This dataset consists of over 4,000 unique pancreas-specific biological images available for interactive browsing at https://pancreatlas.org/. Data was generated using tissue from de-identified organ donors (908 unique individuals) studied by several national and international phenotyping consortia. Image modalities include traditional histology, brightfield and fluorescence immunohistochemistry, and multiplex immunohistochemistry, and many images are large cross-sectional regions measuring &gt; 100 square millimeters (approximately 500,000-2 million cells per image). The dataset aims to document pancreatic architecture over the human lifespan and represents ages from birth to 79 years. Approximately two-thirds of the data was generated from nondiabetic individuals with the remaining third composed of donors with type 1, type 2, cystic fibrosis-related, neonatal, and atypical diabetes. It is an important and unique reference dataset for the pancreas biology research field, with expression patterns of 70+ proteins visualized by immunohistochemistry, in addition to rich information available from traditional histological imaging.</p>
              </div>
            </div>
          </div>
          <ul>
            <li>Number of dataset collections: 10</li>
            <li>Total number of Images: 3,316 (3,713 across collections, due to multiple contexts of interest)</li>
            <li>Number of modalities: 5</li>
            <li>Number of file formats: 9</li>
          </ul>

        <p>The data file manifest is <strong><a href="">available here</a></strong>.</p>

          <table className='table'>
                <thead>
                    <tr>
                        <th>Collections/Modalities</th>
                        <th>CODEX</th>
                        <th>FL IHC</th>
                        <th>Histology</th>
                        <th>IHC</th>
                        <th>IMC</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Cystic Fibrosis-Related Diabetes (CFRD)</td>
                        <td>0</td>
                        <td>24</td>
                        <td>7</td>
                        <td>55</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Human Pancreas Analysis Program (HPAP)</td>
                        <td>77</td>
                        <td>0</td>
                        <td>999</td>
                        <td>0</td>
                        <td>365</td>
                    </tr>
                    <tr>
                        <td>nPOD case #6362</td>
                        <td>0</td>
                        <td>1</td>
                        <td>9</td>
                        <td>29</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Neonatal Development & Early Life Pancreas (HANDEL-P)</td>
                        <td>0</td>
                        <td>457</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Exocrine pancreas in type 1 diabetes</td>
                        <td>0</td>
                        <td>79</td>
                        <td>56</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Integrated Islet Distribution Program (IIDP)</td>
                        <td>0</td>
                        <td>930</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Early-stage type 2 diabetes</td>
                        <td>16</td>
                        <td>96</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Exeter Archival Diabetes Biobank (EADB)</td>
                        <td>0</td>
                        <td>0</td>
                        <td>152</td>
                        <td>193</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Exocrine pancreas pathophysiology</td>
                        <td>0</td>
                        <td>0</td>
                        <td>446</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Pediatric immune tissue development (HANDEL-I)</td>
                        <td>0</td>
                        <td>0</td>
                        <td>575</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </tbody>
          </table>

          </div>




    )
}}