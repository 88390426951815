import React from 'react'


export default class ImageFetchError extends React.Component {
  render () {
    return (
      <div className='error-image'>
        <p>Error Fetching Image</p>
      </div>
    )
  }
}

Error.defaultProps = {
  error_title: 'Unknown Error',
  error_desc: 'No more info'
}
