import React from "react";
import { Card, CardBody, CardFooter, Button, Row } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { List } from "react-content-loader";

import { Error, sortAges} from "../utils";
import { Link } from "react-router-dom";

import ImageContainer from "../utils/image-card-thumb/image-container";
import { fetchImage } from "../../tools/api";

export default class OmicsCard extends React.Component {
  constructor(props) {
    super(props);

    this.toggleTooltip = this.toggleTooltip.bind(this);

    this.state = {
      loaded: false,
      imgUrl: null,
      imgName: null,
      omeroId: null,
      imgTags: null,
      match: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const omicsData = await fetchImage(this.props.iid, this.props.dataType);
      if (omicsData.kvals.length > 0) {
        let kvals = {};
        omicsData.kvals.forEach((kv) => {
          kvals[kv.tagset] = kv.tag;
        })
        this.setState({
          loaded: true,
          imgUrl: omicsData.thumb_url,
          imgName: omicsData.id,
          omeroId: omicsData.iid,
          imgTags: omicsData.tags,
          donorKeys: omicsData.donor_kvals,
          sampleKeys: omicsData.sample_kvals,
          kvals: kvals,
          did: omicsData.did
        });
      }

     
    } catch (error) {
      this.setState({
        loaded: false,
        error: error,
      });
      console.error("Error fetching data:", error);
    }
  }

  toggleTooltip() {
    this.setState({
      ttOpen: !this.state.ttOpen,
    });
  }




  render() {
    

    if (this.state.loaded) {
      const sortedAges = sortAges(this.state.donorKeys["Donor info - Age"])
      const sortedDd =  sortAges(this.state.donorKeys["Donor info - Disease Duration"])
      return (
        <Card className="image-card h-100">
          <ImageContainer
            iid={this.props.iid}
            did={this.props.did}
            callback={this.props.callback}
            src={this.state.imgUrl}
            alt={"Processed from original data: " + this.state.imgName}
          />

          <CardBody className="d-flex flex-column">
            {/* <div>
                    <strong>Assay type: </strong>
                    {this.state.relevantKeys["resource_id"]}
            </div> */}
            <div>
                    <strong>Donors: </strong>
                    {this.state.donorKeys["Donor info - Donor ID"].length}
            </div>
            <div>
                    <strong>Age: </strong>
                    {sortedAges[0]} - {sortedAges[sortedAges.length - 1]}
            </div>
            <div>
                    <strong>Sex: </strong>
                    {this.state.donorKeys["Donor info - Sex"].join(", ")}
            </div>
            <div>
                    <strong>Disease Status: </strong>
                    {this.state.donorKeys["Donor info - Disease Status"].join(", ")}
            </div>
            <div>
                    <strong>Disease Duration: </strong>
                    {this.state.donorKeys["Donor info - Disease Duration"].length > 1 ? sortedDd[0] + " - " + sortedDd[sortedDd.length - 1] : this.state.donorKeys["Donor info - Disease Duration"]}
            </div>
            <div>
                    <strong>Sample Type: </strong>
                    {this.state.sampleKeys["Sample info - Sample Type"].join(", ")}
            </div>
            {/* <div>
                    <strong>Contributor: </strong>
                    {this.state.kvals["Data info - Contributor"]}
            </div> */}
            
          </CardBody>
          <CardFooter>
            <Row>
              <div className="w-100 card-footer-buttons">
                <Link
                  to={
                    this.props.did !== undefined
                      ? `/datasets/${this.props.did}/explore/omics/${this.props.iid}`
                      : `/explore-all-images/${this.props.iid}`
                  }
                >
                  <Button
                    id="modal-button"
                    outline
                    color="secondary"
                    className="mt-auto"
                  >
                    Preview <FontAwesomeIcon icon="search-plus" size="1x" />
                  </Button>
                </Link>
                {this.props.isFavorite && (
                  <Button
                    id="save-button"
                    outline
                    color="info"
                    className="favorite"
                    onClick={() =>
                      this.props.favoriteCallback([
                        [this.props.iid, this.props.did],
                      ])
                    }
                  >
                    Save{" "}
                    <FontAwesomeIcon icon={["far", "bookmark"]} size="1x" />
                  </Button>
                )}
                {!this.props.isFavorite && (
                  <Button
                    id="unsave-button"
                    outline
                    color="danger"
                    className="favorite"
                    onClick={() =>
                      this.props.favoriteCallback([
                        [this.props.iid, this.props.did],
                      ])
                    }
                  >
                    Remove
                    <FontAwesomeIcon icon={["fas", "bookmark"]} size="1x" />
                  </Button>
                )}
              </div>
            </Row>
          </CardFooter>
        </Card>
      );
    } else if (this.state.error !== undefined) {
      return <Error error_desc={this.state.error.message} />;
    } else {
      return (
        <Card className="image-card h-100">
          <CardBody className="d-flex flex-column">
            <List animate />
          </CardBody>
        </Card>
      );
    }
  }
}

OmicsCard.defaultProps = {
  imgUrl: "//via.placeholder.com/350x350",
  imgName: "Placeholder name",
};
