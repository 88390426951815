import React from "react";
import useIntersectionObserver from './useIntersectionObserver'
import Image  from './image.js'
import { Link } from 'react-router-dom'
import {
    FontAwesomeIcon
  } from '@fortawesome/react-fontawesome'
const ImageContainer = (props) => {
    const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);
useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    }
  });



  const aspectRatio = 1;
return (
  <Link to={props.did == "1138" ?`/datasets/${props.did}/explore/omics/${props.iid}`: props.did !== undefined ? `/datasets/${props.did}/explore/${props.iid}` : `/explore-all-images/${props.iid}`}>
    <div
      ref={ref}
      className="card-img-wrapper"
      style={{ paddingBottom: `${aspectRatio}%` }}
      
    >
      {isVisible && (
        <Image  src={props.src} alt={props.alt} />
       )}
       <FontAwesomeIcon icon='search-plus' size='3x' className='card-search-plus' />
    </div></Link>
  );
};

export default ImageContainer