import React from "react";
import { Card, CardBody, CardFooter, Button, Row } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { List } from "react-content-loader";

import { Error, MarkerTag } from "../utils";
import { Link } from "react-router-dom";

import ImageContainer from "../utils/image-card-thumb/image-container";
import { fetchImage } from "../../tools/api";

export default class ImageCard extends React.Component {
  constructor(props) {
    super(props);

    this.toggleTooltip = this.toggleTooltip.bind(this);

    this.state = {
      loaded: false,
      imgUrl: null,
      imgName: null,
      omeroId: null,
      imgTags: null,
      match: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const imageData = await fetchImage(this.props.iid, this.props.dataType);
      let kvals = imageData.kvals;

      let hasLink = Object.keys(kvals).includes("Program ID link");
      this.setState({
        loaded: true,
        imgUrl: "/" + imageData.iname,
        imgName: imageData.iname,
        omeroId: imageData.iid,
        imgTags: imageData.tags,
        markers: imageData.markers,
        donor: imageData.donor,
        idLink: hasLink ? kvals["Program ID link"].val : undefined,
        region: imageData.region,
        did: imageData.did
      });
    } catch (error) {
      this.setState({
        loaded: false,
        error: error,
      });
      console.error("Error fetching data:", error);
    }
  }

  toggleTooltip() {
    this.setState({
      ttOpen: !this.state.ttOpen,
    });
  }

  render() {
    if (this.state.loaded) {
      return (
        <Card className="image-card h-100">
          <ImageContainer
            iid={this.props.iid}
            did={this.props.did}
            callback={this.props.callback}
            src={`${process.env.REACT_APP_IMAGE_URL}/${this.state.did}/${this.props.iid}.jpg`}
            alt={"Processed from original data: " + this.state.imgName}
          />

          <CardBody className="d-flex flex-column">
            {this.state.donor.map((key) => {
              if (key.key === "Program ID" && this.state.idLink !== undefined) {
                return (
                  <div key={`${this.props.iid}-${key.key.replace(" ", "_")}`}>
                    <strong>{key.key}: </strong>
                    <a href={this.state.idLink}>
                      <u>{key.value}</u>
                    </a>
                  </div>
                );
              } else {
                return (
                  <div key={`${this.props.iid}-${key.key.replace(" ", "_")}`}>
                    <strong>{key.key}: </strong>
                    {key.value}
                  </div>
                );
              }
            })}
            <div>
              <strong>Markers:</strong>
            </div>
            <div className="marker-list">
              {this.state.markers.map((marker) => (
                <MarkerTag
                  filterActive={this.props.filterActive}
                  key={`${marker.val}`}
                  filterCallback={this.props.filterCallback}
                  marker={marker.val}
                  iid={this.props.iid}
                  color={marker.color}
                />
              ))}
            </div>
            {this.state.region.length != 0 ?
              <div className="region-info">
                <strong>Region: </strong>
                {this.state.region.map(item => item.value).join(", ")}
              </div>
            :null}
            <div>
              <strong>Other Tags: </strong>
              <span>{this.state.imgTags.join(", ")}</span>
            </div>
          </CardBody>
          <CardFooter>
            <Row>
              <div className="w-100 card-footer-buttons">
                <Link
                  to={
                    this.props.did !== undefined
                      ? `/datasets/${this.props.did}/explore/${this.props.iid}`
                      : `/explore-all-images/${this.props.iid}`
                  }
                >
                  <Button
                    id="modal-button"
                    outline
                    color="secondary"
                    className="mt-auto"
                  >
                    Preview <FontAwesomeIcon icon="search-plus" size="1x" />
                  </Button>
                </Link>
                {this.props.isFavorite && (
                  <Button
                    id="save-button"
                    outline
                    color="info"
                    className="favorite"
                    onClick={() =>
                      this.props.favoriteCallback([
                        [this.props.iid, this.props.did],
                      ])
                    }
                  >
                    Save{" "}
                    <FontAwesomeIcon icon={["far", "bookmark"]} size="1x" />
                  </Button>
                )}
                {!this.props.isFavorite && (
                  <Button
                    id="unsave-button"
                    outline
                    color="danger"
                    className="favorite"
                    onClick={() =>
                      this.props.favoriteCallback([
                        [this.props.iid, this.props.did],
                      ])
                    }
                  >
                    Remove
                    <FontAwesomeIcon icon={["fas", "bookmark"]} size="1x" />
                  </Button>
                )}
              </div>
            </Row>
          </CardFooter>
        </Card>
      );
    } else if (this.state.error !== undefined) {
      return <Error error_desc={this.state.error.message} />;
    } else {
      return (
        <Card className="image-card h-100">
          <CardBody className="d-flex flex-column">
            <List animate />
          </CardBody>
        </Card>
      );
    }
  }
}

ImageCard.defaultProps = {
  imgUrl: "//via.placeholder.com/350x350",
  imgName: "Placeholder name",
};
