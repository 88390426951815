

export const   sortAges = (ages) => {
    return ages
    .map(age => ({ original: age, days: normalizeAge(age) }))
    .sort((a, b) => a.days - b.days)
    .map(age => age.original);
}

const normalizeAge = (age) => {
    const agePattern = /(\d+)(y|mo|d)/;
    const match = agePattern.exec(age);
    if (!match) return 0;

    const value = parseInt(match[1]);
    const unit = match[2];

    switch (unit) {
      case 'y':
        return value * 365;
      case 'mo':
        return value * 30;
      case 'd':
        return value;
      default:
        return 0;
    }
  };