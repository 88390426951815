import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button, Container } from "reactstrap";

// import MetaTags from "react-meta-tags";

import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DetailRow, sortAges } from "../utils";
import { fetchImage } from "../../tools/api"

export const OmicsPage = (props) => {
  let params = useParams();

  let defs = require("../../assets/txt/definitions.json");
  const [isFavorite, setIsFavorite] = useState(false);
  const [did] = useState(params.did);
  const [iid] = useState(params.iid);
  const [omicsData, setomicsData] = useState({
    img_id: 0,
    did: 0,
    kvals: {},
    path_path: "",
    markerColors: {},
    page_title_meta: "",
    markers: [],
    donorKeys: [],
    sampleKeys: [],
    repoLink: "",
    isLoaded: false,
  });
  const [datasetKvals] = useState({
    dsName: "Omics Dataset",
  });

  const doi_url = omicsData.kvals["Data info - DOI"];

  useEffect(() => {
    async function fetchData() {
      try {
        const omicsData = await fetchImage(iid,'scRNA-seq');
        if (omicsData.kvals.length > 0) {
          let kvals = {};
          omicsData.kvals.forEach((kv) => {
            kvals[kv.tagset] = kv.tag;
          })
  
          setomicsData({
            img_id: iid,
            did: omicsData.did,
            kvals: kvals,
            path_path: omicsData.pathpath,
            isLoaded: true,
            name: omicsData.name,
            dataType: omicsData.data_type,
            donorKeys: omicsData.donor_kvals,
            sampleKeys: omicsData.sample_kvals,
            repoLink: omicsData.sample_kvals['Sample info - Public Data Repository'][0],
            img_src: omicsData.thumb_url
          });
        } else {
          setomicsData({
            img_id: iid,
            kvals: { Error: "No information for this image" },
            path_path: omicsData.pathpath,
          });
        }

        // const datasetData = await fetchDataset(omicsData.did);
        // setDatasetKvals({
        //   dsName: "Omics Dataset",
        //   kvals: datasetData.kvals,
        // });
  
        
  
        if (omicsData !== undefined) {
          setIsFavorite(
            omicsData !== undefined && props.favorites.includes(`${iid}`)
          );
        }
  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchData();
  
  }, []);

  

  const favorite = () => {
    setIsFavorite(!isFavorite);
    props.favoriteCallback([[omicsData.img_id, omicsData.did]]);
  };

//   let tinycolor = require("tinycolor2");
  let labelRe = /^(?!Hex code)([a-zA-Z]+\s+info)?(\s+-\s+)?(.+)$/;


  return (
    <>
      {/* <MetaTags>
        <title>{omicsData.kvals.page_title_meta}</title>
        <meta
          name="description"
          content={`Image details for: ${omicsData.kvals.page_title_meta}`}
        />
      </MetaTags> */}

      <Container className="image-detail-container">
        <div className="image-data-container">
          {
            <div className="image-data">
              <div className="cookiecrumb">
                Home {">"}{" "}
                <a href={`/datasets/${omicsData.did}/explore`}>{datasetKvals.dsName}</a>
              </div>
              <div className="image-title">
                <h1>
                    {/* need to set HTML here to display bold values */}

                </h1>
              </div>

              <Row className="image-page-top-panel rounded">
                <Col md="5" sm="12" xs="12">
                  <div className="page-image">
                    <Row>
                      <Col md="12" sm="12" style={{ padding: "0" }}>
                        <a
                          href={omicsData.path_path}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="pathviewer-button"
                        >
                          <Button
                            className="btn-info btn-lg btn-block btn-open-pathviewer"
                            color="secondary"
                          >
                            Open in Vitessce{" "}
                            <FontAwesomeIcon
                              size="1x"
                              icon="external-link-alt"
                            />
                          </Button>
                        </a>

                        <a
                          href={omicsData.path_path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={omicsData.img_src}
                            alt={omicsData.img_id}
                            className="page-image img-fluid"
                          />
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" sm="12" xs="12" style={{ padding: "0" }}>
                        <div className="image-markers">
                          
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md="7" sm="12" xs="12">
                  <Row>
                    <Col md="6">&nbsp;</Col>
                    <Col md="6">
                      <div className="w-100 image-actions">
                        {!isFavorite && (
                          <Button
                            outline
                            color="success"
                            className="favorite btn btn-outline-info"
                            onClick={() => favorite()}
                          >
                            Save for later{" "}
                            <FontAwesomeIcon
                              icon={["far", "bookmark"]}
                              size="1x"
                            />
                          </Button>
                        )}
                        {isFavorite && (
                          <Button
                            outline
                            color="danger"
                            className="favorite btn btn-outline-info"
                            onClick={() => favorite()}
                          >
                            Remove from bookmarks{" "}
                            <FontAwesomeIcon
                              icon={["fas", "bookmark"]}
                              size="1x"
                            />
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Table id="table-image-page-top-panel">
                    <tbody>
                      <tr>
                        <th>Datatype</th>
                        <td>
                          <div>
                            {omicsData.dataType}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <td>
                          <div>
                            {omicsData.name}
                          </div>
                        </td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <th>Collection</th>
                        <td>
                          <a href={`/datasets/${did}/overview`}>
                            {datasetKvals.dsName}
                          </a>
                        </td>
                      </tr>
            
                      {/* <tr>
                        <th>Contributor</th>
                        <td>{omicsData.kvals["Data info - Contributor"]}</td>
                      </tr> */}
                      <tr>
                        <th>DOI</th>
                        {omicsData.kvals["Data info - DOI"] !== undefined && omicsData.kvals["Data info - DOI"] !== "" ? (
                          <td>
                            <a href={`${doi_url}`} target="_blank"
                            rel="noopener noreferrer">
                              {omicsData.kvals["Data info - DOI"]}{" "}
                              <FontAwesomeIcon icon="external-link-alt" />
                            </a>
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                      <tr>
                        <th>Public Data Repository</th>
                        <td>
                        <a href={omicsData.repoLink.startsWith('GSE') ? `https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${omicsData.repoLink}` : omicsData.repoLink}>
                            {omicsData.sampleKeys['Sample info - Public Data Repository']}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row>
                <Col md="12" sm="12">
                  <div className="text-left">
                    <p className="">
                      For descriptions of metadata fields and abbreviations,
                      click{" "}
                      <Link
                        to={"/nomenclature"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </Link>
                      .
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="bottom-kvals-section">
                <Col md="6" sm="12">
                  <Table>
                    <thead>
                      <tr>
                        <th colSpan="2">
                          <h5>
                            <strong>Donor Information</strong>
                          </h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {Object.keys(omicsData.donorKeys).map((key) => {
                              if(!key.includes("Unique Identifier")){
                                if(key.includes("Disease Duration") || key.includes("Age")){
                                 omicsData.donorKeys[key] = sortAges(omicsData.donorKeys[key])
                                }
                                return (
                                    <DetailRow
                                    key={omicsData.donorKeys[key]}
                                    data={omicsData.donorKeys[key].join(", ")}
                                    desc={
                                      defs["Image Tags"][key] !== undefined
                                        ? defs["Image Tags"][key]["Description"]
                                        : defs["Collection-specific Data"][key] !==
                                          undefined
                                        ? defs["Collection-specific Data"][key]["Description"]
                                        : null
                                    }
                                    heading={labelRe.exec(key)[3]}
                                  />
                                );
                              }
                              
                              
                                
                            })}
                    </tbody>
                  </Table>
                </Col>
                <Col md="6" sm="12">
                  <Table>
                    <thead>
                      <tr>
                        <th colSpan="2">
                          <h5>
                            <strong>Sample Information</strong>
                          </h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {Object.keys(omicsData.sampleKeys).map((key) => {
                              if(!key.includes("Public Data Repository")){
                                return (
                                  <DetailRow
                                  key={omicsData.sampleKeys[key]}
                                  data={omicsData.sampleKeys[key].join(", ")}
                                  desc={
                                    defs["Image Tags"][key] !== undefined
                                      ? defs["Image Tags"][key]["Description"]
                                      : defs["Collection-specific Data"][key] !==
                                        undefined
                                      ? defs["Collection-specific Data"][key]["Description"]
                                      : null
                                  }
                                  heading={labelRe.exec(key)[3]}
                                />
                              );
                              }   
                            })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          }
        </div>
      </Container>
    </>
  );
  // }
};

export default OmicsPage;
