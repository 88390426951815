import React from 'react'
import ReactGA from 'react-ga'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table
} from 'reactstrap'

import MetaTags from 'react-meta-tags'
import omicsBanner from '../../assets/img/datasets/1138/omics-collection-banner.jpg'



import {
  Link,
  withRouter
} from 'react-router-dom'

import { Parallax } from 'react-parallax'

class OmicsOverview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Single Cell OMICs Datasets',
      desc: 'Default desc',
      did: 1138,
      logo: omicsBanner
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render() {
    
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <div className='datasetOverviewWrapper'>

        <MetaTags>
          <title>{this.state.title} -- Pancreatlas</title>
          <meta name='description' content={this.state.long_desc} />
        </MetaTags>

        <Parallax
          blur={0}
          bgImage={this.state.logo}
          bgImageAlt='Sample Image'
          strength={500}
        >
          <div className='parallax-filler' style={{ height: '30vh' }}>
            <Container className='h-100'>
              <Row className='h-100'>
                <Col md='12' className='d-flex align-items-center'>
                  <span className='dataset-title'><h1><strong>Collection: {this.state.title}</strong></h1>
                    <h3>
                      {this.state.short_desc}
                    </h3>
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </Parallax>
        <Container className='mt-4'>
          <Row className='mb-4 mt-4'>
            <Col md='12'>
              <Row className='my-4'>
                <Col md='6'>
                  <div className='dataset-description'>
                     <p>In order to integrate data across modalities, we will be adding carefully selected single cell OMICs datasets to include in Pancreatlas. As needed, any datasets referenced below have been filtered to contain only human data.</p>
                     
                    <ol> 
                        <li>Shrestha, Suanders, Walker et al. (2021) investigated transcription factor heterogeneity in human islet cells using single-cell RNA sequencing of over 40,000 cells. They identified subpopulations of α and β cells based on combinatorial transcription factor expression patterns. Notably, α cells co-expressing ARX/MAFB and β cells co-expressing MAFA/MAFB showed enhanced expression of genes related to glucose sensing and hormone secretion, indicating higher functionality and maturity. Reference: <a href='https://doi.org/10.1172/jci.insight.151621' >https://doi.org/10.1172/jci.insight.151621</a></li>
                        <br/>
                        <li>Tritschler et al. (2022) developed a cross-species transcriptional map of over 50,000 pancreatic islet cells from humans, pigs, and mice. This resource enables comparative study of islet cell heterogeneity, function, maturation, and stress responses across species. They identified human α and β cell states that activate distinct programs when responding to external stressors. Importantly, they found that multiple functional and regulatory human expression patterns, as well as stress-related heterogeneity, are better conserved in pig islet cells than in mouse cells. Reference:  <a href='https://doi.org/10.1016/j.molmet.2022.101595'>https://doi.org/10.1016/j.molmet.2022.101595</a> </li>
                    </ol>
                </div>
                </Col>
                <Col md='6'>
                  <div>
                    
                  </div>
                  <div className='mt-4 pt-4'>
                    <h4>Dataset Quick Reference</h4>
                  </div>

                  <Table>
                    <tbody>
                    <tr>
                      <th className="table-header-row">No. of Datasets</th>
                      <td><Link to={`/datasets/1138/explore`}><Button className="btn-info btn-sm"> 2 </Button></Link></td>
                    </tr>
                    <tr>
                      <th>Initial Publication</th>
                      <td>v1.12 (2025-04-10)</td>
                    </tr>
                    <tr>
                        <th>Image Type(s)</th>
                        <td>scRNAseq</td>
                    </tr>
                    
                    <tr>
                        <th>References</th>
                        <td>See highlighted at left</td>
                    </tr>
                    
                    <tr>
                      <th>Source Import Data</th>
                      <td className='d-flex flex-column'>
                        <a href="https://firebasestorage.googleapis.com/v0/b/pancreatlas-dev.appspot.com/o/import_spreadsheets%2F20240708_Shrestha_et_al_JCIInsight_2021.xlsx?alt=media&token=d3f2285f-e20d-4757-9b13-9daa8c0a0e5e" target='_blank' rel="noopener noreferrer"> Download Shrestha, Suanders, Walker et al. (2021)</a>
                        <br/>
                        <a href='https://firebasestorage.googleapis.com/v0/b/pancreatlas-dev.appspot.com/o/import_spreadsheets%2F20240708_Tritschler_et_al_MolMet_2022_metadata.xlsx?alt=media&token=e4eb7763-d38f-4828-adb4-03adc151bd01' target='_blank' rel="noopener noreferrer">Download Tritschler et al. (2022)</a>
                      </td>
                    </tr>
                    </tbody>
                  </Table>
                  
                </Col>
                
              </Row>         
              <Row className='my-4'>
                <Col md='12'>
                  <h3 className='mt-4'>Here are some suggested projections of the data within this set:</h3>
                </Col>
              </Row>
              <Row className='my-4'>
                {this.state.did === "531" ? <Col md='4'>
                  <Card className='h-100'>
                    <CardBody>
                      <h3>Browse by Age</h3>
                      <p>Choose a specific age range of donors within which to view samples</p>
                      <Link to={`/datasets/1138/browse-by-age`}>
                        <Button>Browse</Button>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>: null}
                <Col md='4'>
                  <Card className='h-100'>
                    <CardBody>
                      <h3>View All OMICs Datasets</h3>
                      <p>Browse the entire collection without any filtering</p>
                      <Link to={`/datasets/1138/explore`}>
                        <Button>Browse</Button>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col md='12'>
              <p dangerouslySetInnerHTML={{ __html: this.state.sponsors }} />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default withRouter(OmicsOverview)
